<template>
  <v-container align="center" fluid class="d-flex justify-center mb-15">
    <v-row class="d-flex justify-center">
      <v-col cols="12" class="d-flex justify-center pb-0">
        <v-card width="850" elevation="3" id="dataapp">
          <v-card-title class="ml-10 mt-5"> </v-card-title>

          <v-card-text>
            <v-row class="ml-10" style="border-bottom: 1px groove">

              <v-col cols="12" class="pt-0">
                <p style="font-size: 2.1rem; color: #757575">
                  CHALAN
                </p>
              </v-col>

              <v-row>
                <v-col cols="6">
                  <v-col class="py-0"
                    ><p class="font-weight-medium py-0 mb-0">
                      BILL FROM :
                    </p></v-col
                  >
                  <v-col class="pb-0"
                    ><p class="mb-0 text-body-1">
                      {{ billFromInfo.name }}
                    </p></v-col
                  >
                  <v-col class="py-0">
                    <p class="text-body-1 mb-0">
                      {{ billFromInfo.location }}
                    </p>
                  </v-col>
                </v-col>
                <v-col cols="6" align-self="end" >

                  <p class="mb-0 font-weight-medium">BILL TO :</p>
                  <p class="text-body-1 mb-0">{{ billTo.name }}</p>
                  <p class="text-body-1 mb-0">{{ billTo.location }}</p>
                </v-col>
              </v-row>
            </v-row>
            <div>
              <v-card min-height="500" elevation="0" class="mt-2">
                <v-data-table
                  :headers="headers"
                  :items="orderAllArray"
                  item-key="id"
                  class="elevation-0 mx-12"
                  hide-default-footer
                  dense
                >
                </v-data-table>
                <!-- 
                <v-row>
                  <v-col cols="7"> </v-col>

                  <v-col cols="4" class="pr-0">
                    <div>
                      <div class="d-flex flex-row align-center pr-5">
                        <p class="mb-1 text-body-1 ml-2">Sub Total</p>
                        <v-spacer></v-spacer>
                        <p class="mb-1 text-body-1 mr-0 mb-0">
                          {{ orderinfo.sub_price }}
                        </p>
                      </div>
                      <div class="d-flex flex-row align-center pr-5">
                        <p class="text-body-1 ml-2 mb-0">Discount</p>
                        <v-spacer></v-spacer>
                        <p class="mr-0 text-body-1 mb-0">
                          {{ orderinfo.pos_additional_discount }}
                        </p>
                      </div>
                      <div class="d-flex flex-row align-center pr-5">
                        <p class="text-body-1 ml-2">VAT</p>
                        <v-spacer></v-spacer>
                        <p class="text-body-1 mr-0">
                          {{ orderinfo.vat }}
                        </p>
                      </div>
                      <div
                        class="d-flex flex-row align-center pr-5"
                        style="background-color: #cfd3d4"
                      >
                        <p class="mt-2 mb-1 font-weight-medium ml-2 mb-2">
                          Grand Total
                        </p>
                        <v-spacer></v-spacer>
                        <p class="mt-2 mb-1 font-weight-medium mr-0">
                          {{ orderinfo.price_total }}
                        </p>
                      </div>
                    </div>
                  </v-col>
                </v-row> -->

                <!-- <v-card v-if="orderinfo.order_status == 'Paid'" elevation="0">
                  <div>
                    <div style="border: 1px groove" class="mx-12">
                      <v-row>
                        <v-row class="ml-5">
                          <v-col class="" cols="3"> Transaction Date </v-col>
                          <v-col cols="3"> GateWay</v-col>
                          <v-col cols="3"> Transaction ID </v-col>
                          <v-col cols="3"> Amount</v-col>
                        </v-row>
                      </v-row>
                    </div>
                    <div style="border: 1px groove" class="mx-12">
                      <v-row>
                        <v-row class="ml-5">
                          <v-col class="" cols="3">
                            <p
                              class="text-body-1 mb-0"
                              v-if="orderinfo.ordered_date"
                            >
                              {{ orderinfo.ordered_date }}
                            </p>
                          </v-col>
                          <v-col cols="3" class="text-body-1 mb-0"> N/A</v-col>
                          <v-col cols="3" class="text-body-1 mb-0"> N/A </v-col>
                          <v-col cols="3">
                            <p
                              v-if="orderinfo.grand_total"
                              class="text-body-1 mb-0"
                            >
                              {{ orderinfo.grand_total }}
                            </p>
                          </v-col>
                        </v-row>
                      </v-row>
                    </div>
                  </div>
                </v-card> -->
              </v-card>

              <!-- <v-row class="ml-10 d-flex flex-row">
                <v-col cols="7">
                  <p class="font-weight-medium"></p>
                  <p class="text-caption">
                    This Invoice Is Autogenareted By
                    {{ companyinfo.name }} After Confirming Your Order And
                    Payment
                  </p>
                </v-col>
              </v-row> -->
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" class="d-flex justify-center py-0">
        <v-card elevation="0" class="mt-0">
          <v-btn
            text
            @click="printData"
            class="pa-0"
            style="padding-left: 5px !important"
          >
            <v-icon class="pr-1" large color="black">mdi-printer </v-icon>
            Print
          </v-btn>
          <v-btn
            text
            @click="printData"
            style="padding-left: 0px !important; padding-right: 10px !important"
          >
            <v-icon class="pr-0" large color="black">mdi-download </v-icon>
            download
          </v-btn>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
       


<script>
export default {
  data: () => ({
    invoiceinfo: {},
    orders: [],
    orderAllArray: [],
    orderinfo: {},
    bill: [],
    specifications: [],
    addquantitydialog: false,
    companyinfo: {},
    invoicearray: [],
    userdata: {},
    contact: {},
    specifications: [],

    confirmbtn: false,

    menu: false,
    snackbar: false,
    text: "",
    viewoptiondialog: false,
    addquantitydialog: false,
    shop: [],
    Return_status: "Returned",
    warehouse: [],
    subtractWarhouse: [],
    subtractShop: [],
    quantityIndex: -1,
    totalSubtractCountOfWarhouse: 0,
    totalSubtractCountOfShop: 0,
    quantity: 0,
    validEditForm: false,
    totalRemaining: 1,
    specification: {},
    specific: {},
    bigArray: [],
    invoicearray: [],
    value_store: [],
    last_value: [],
    newinvoicearray: [],
    color: "",
    billFromInfo: {},
    billTo: {},

    headers: [
      {
        text: "Name",
        sortable: false,
        value: "Product.title",
        class: "blue-grey lighten-4 text-body-2 tableheader",
      },
      {
        text: "Color",
        value: "product_specification.color",
        sortable: false,
        class: "blue-grey lighten-4 text-body-2 tableheader",
      },
      {
        text: "Size",
        value: "product_specification.size",
        sortable: false,
        class: "blue-grey lighten-4 text-body-2 tableheader",
      },
      {
        text: " Unit",
        value: "product_specification.unit",
        sortable: false,
        class: "blue-grey lighten-4 text-body-2 tableheader ",
      },

      {
        text: "Quantity ",
        value: "quantity",
        sortable: false,
        class: "blue-grey lighten-4 text-body-2 tableheader",
      },
      //   {
      //     text: "Unit Price ",
      //     value: "unit_price",
      //     sortable: false,
      //     class: "blue-grey lighten-4 text-body-2 tableheader",
      //   },
      //   // {
      //   //   text: "Discount ",
      //   //   value: "total_discount",
      //   //   sortable: false,
      //   //   class: "blue-grey lighten-4 text-body-2 ",
      //   // },
      //   {
      //     text: "Amount",
      //     value: "total_price",
      //     sortable: false,
      //     class: "blue-grey lighten-4 text-body-2 tableheader",
      //   },
    ],
  }),
  watch: {
    subtractWarhouse: {
      // This will let Vue know to look inside the array
      deep: true,

      // We have to move our method to a handler field
      handler: "totalProductQuantitiyCountOfWarhouse",
    },

    subtractShop: {
      // This will let Vue know to look inside the array
      deep: true,

      // We have to move our method to a handler field
      handler: "totalProductQuantitiyCountOfShop",
    },
    addquantitydialog: function (newValue) {
      this.totalSubtractCountOfWarhouse = "0";
      this.totalSubtractCountOfShop = "0";
      this.subtractWarhouse.length = 0;
      this.subtractShop.length = 0;
    },
  },
  methods: {
    initialize(item) {
      axios
        .get(`manager/specific_transfer/${this.$route.query.id}`)
        .then((response) => {
          this.orderAllArray = response.data.data;
          // console.log("this is all data", response.data);

          this.billFromInfo = response.data.request_from;
          this.billTo = response.data.request_to;
          this.orderinfo = response.data.order_data;

          this.companyinfo = response.data.company_data;
          this.userdata = response.data.billing_address_data;
          //console.log("table info", this.orders);
          // console.log("this is company data", response.data);
          this.totalRemaining = this.orders.reduce(
            (previous, current) => previous + current.remaining,
            0
          );
          this.companyinfo = response.data.company_data;
        });
    },

    //   printData() {
    // //   var divToPrint = document.getElementById("dataapp");
    // //   var style = "<style>";
    // //   style = style + "table {width: 100%;font: 17px Calibri;}";
    // //   style =
    // //     style +
    // //     "table, th, td {border: solid 1px #DDD; border-collapse: collapse;";
    // //   style = style + "padding: 2px 3px;text-align: center;}";
    // //   style = style + "</style>";

    // //   var win = window.open("", "", "height=1900,width=1900");
    // //   win.document.write(style); //  add the style.
    // //   win.document.write(divToPrint.outerHTML);
    // //   win.document.close();
    // //   win.print();
    // window.print();
    // },

    // printData() {
    //   var printContents = document.getElementById("dataapp").innerHTML;
    //   var originalContents = document.body.innerHTML;

    //   document.body.innerHTML = printContents;
    //   window.print();

    //   document.body.innerHTML = originalContents;
    // },
    printData() {
      //this.$htmlToPaper("dataapp");
      this.$htmlToPaper("dataapp");
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>

<style scoped>
.tableheader {
  max-height: 32px !important;
}
/* tbody tr:nth-of-type(even) {
  background-color: #e3f2fd !important;
} */
/* .v-data-table > .v-data-table__wrapper > table > tbody > tr :nth-child(even){
 
  background-color: #ff7743;
 
}
table.v-table thead tr{
 max-height:1px !important;
} */
/* 
table.v-table thead tr:nth-child(even) {
  background-color: #dddddd;
} */
/* .invoice{
  background: #f5f5f5; 
  font-size: 10em
}
.invoice .theme--light.v-table{
  background-color: #00f !important;
} */
</style>